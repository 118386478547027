<template>
  <div class="NewsDetail">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="16">
            <div>
              <div class="head">{{newsDetail.title}}</div>
              <div class="date"><span>{{newsDetail.updated_at}}</span> <span>Read:{{newsDetail.hits}}</span> </div>
              <el-divider></el-divider>
              <div class="__cont__"
                   v-html="newsDetail.content"></div>
              <el-divider>end</el-divider>
              <div class="_navss">
                <a :href="'./'+newsDetail.prev.id ? newsDetail.prev.id:''"> <span>Previous Article:</span> {{newsDetail.prev.title ? newsDetail.prev.title:'No more'}}</a>
                <a :href="'./'+newsDetail.next.id ? newsDetail.next.id:''"> <span>Next Article:</span> {{newsDetail.next.title ? newsDetail.next.title:'No more'}}</a>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="tit">
              Life Center
            </div>
            <a :href="'/home/live-in-china-detail/'+item.id"
               class="lslis"
               v-for="(item,index) in list"
               :key="index">
              <el-row :gutter="24">
                <el-col :span="8">
                  <div class="imgbox">
                    <img :src="item.thumb"
                         alt="">
                  </div>
                </el-col>
                <el-col :span="16">
                  <h3>{{item.title}}</h3>
                </el-col>
              </el-row>
            </a>
            <div class="tit">
              News
            </div>
            <router-link :to="'/news-detail/'+item.id"
                         class="lslis"
                         v-for="(item,index) in news"
                         :key="index">
              <el-row :gutter="24">
                <el-col :span="8">
                  <div class="imgbox">
                    <img :src="item.thumb"
                         alt="">
                  </div>
                </el-col>
                <el-col :span="16">
                  <h3>{{item.title}}</h3>
                </el-col>
              </el-row>
            </router-link>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

import axiosApi from "../api/api.js";

export default {

  data () {
    return {
      newsDetail: {
        newsDetail: '',
        content: '',
        updated_at: '',
        next: '',
        prev: '',
      },
      list: [],
      news: []

    }
  },

  beforeMount () {
    console.log()
    axiosApi(
      'newsinfo/' + this.$route.params.id, {}, 'get'
    ).then((res) => {
      this.newsDetail = res.data
    })
    axiosApi('news/4', {}, 'get').then(res => {
      this.list = res.data.data
    })
    axiosApi('news/7', {}, 'get').then(res => {
      this.news = res.data.data
    })
  },
  methods () {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .head {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .date {
    font-size: 14px;
    color: #545454;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  ._navss {
    display: flex;
    justify-content: center;
    a {
      width: 40%;
      display: block;
      line-height: 1;
      transition: ease 0.5s;
      margin-bottom: 10px;
      padding: 10px;
      span {
        display: block;
        margin-bottom: 10px;
        color: #ffc107;
        font-weight: bold;
      }
      &:nth-child(1) {
        text-align: right;
        border-right: 1px solid #ffc107;
      }
      &:hover {
        color: #ffc107;
      }
    }
  }
}
.lslis {
  display: block;
  margin-bottom: 20px;
  h3 {
    font-size: 14px;
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1;
}
</style>

<style lang="scss">
.__cont__ {
  margin-top: 30px;
  img {
    max-width: 100%;
  }
}
</style>